import React from 'react';
import { withRouter } from 'react-router';
import Logo from 'Assets/Logo for Media.png';
import BackgroundImg from 'Assets/thyroid.jpg';
import { Route, Switch, Redirect } from 'react-router-dom';
import Estimator from 'Estimator/Estimator';
import Authentication from 'Authentication/Authentication';
import Disclaimer from 'Disclaimer/Disclaimer';
import PaymentRequest from 'PaymentRequest/PaymentRequest';
import TokenPayment from 'TokenPayment/TokenPayment';
import PaymentSuccessful from 'PaymentSuccessful/PaymentSuccessful';
import clsx from 'clsx';

function AppRouter(props) {
    return (
        <div className="app-container">
            <div className="app-content-block">
                <div className="app-header">
                    <div className={clsx("app-logo", "desktop-logo")} onClick={() => window.location.href = "https://fitto-at.com/"}>
                        <img src={Logo} alt='Logo' />
                    </div>
                    <div className={clsx("app-logo", "mobile-logo")} onClick={() => props.history.push("/")}>
                        <img src={Logo} alt='Logo' />
                    </div>
                    <div className="app-header-content">
                        <h3>THYDOR® - THYroid DOse Regulator</h3>
                        <p>A Product of <b>FITTO</b></p>
                    </div>
                </div>
                <div className="app-form-container">
                    <Switch>
                        <Route exact path="/" component={Disclaimer} />
                        <Route exact path="/signIn" component={Authentication}/>
                        <Route exact path="/estimator" component={Estimator} />
                        <Route exact path="/paymentRequest" component={PaymentRequest}/>
                        <Route exact path="/paymentSuccessful" component={PaymentSuccessful}/>
                        <Route exact path="/tokenPayment" component={TokenPayment}/>
                        <Route render={() => <Redirect to={{ pathname: "/" }} />} />
                    </Switch>
                </div>
            </div>
            <div className="app-info-block">
                {/* <div className="app-info-text-block">
                    <p className="app-info-heading"></p>
                    <p className="app-info-heading2"></p>
                    <p className="info-text"></p>
                </div> */}
                <img className="app-info-img" src={BackgroundImg} alt='img' />
            </div>
        </div>
    );
}

export default withRouter(AppRouter);