import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import storageSession from 'redux-persist/lib/storage/session'
import { combineReducers, createStore } from "redux";
import authStateReducer from "./Reducers/authReducers";


const persistConfig = {
    key: 'root',
    // storage,
    storage: storageSession,
  }

const allReducers = combineReducers({
    authState: authStateReducer,
  })

const persistedReducer = persistReducer(persistConfig, allReducers)

const store = createStore(persistedReducer)

export default store