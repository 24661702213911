import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER
    }
);

function createAxiosResponseInterceptor(axiosInstance) {
    axiosInstance.interceptors.request.use(function (config) {
        return config
        }
    )
};

createAxiosResponseInterceptor(api);

export default api;