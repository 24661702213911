import React, { useState, useEffect } from 'react';
import clsx from "clsx";
import api from 'Utility/axios.js';
import Loading from 'Loading/Loading';
import ArrowUpIcon from 'Assets/arrow-up.svg';
import ArrowDownIcon from 'Assets/arrow-down.svg';
import style from './Disclaimer.module.css';

function Disclaimer(props) {

    const [showDisclaimer, setShowDisclaimer] = useState(false);
    const [freePeriod, setFreePeriod] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        checkFreePeriod();
        window.scrollTo(0, document.body.scrollHeight);
    }, []);

    function checkFreePeriod(){
        setLoading(true);
        api.get(`/checkFreePeriod`)
            .then(response => {
                setLoading(false);
                var dataResponse = response.data.data;
                setFreePeriod(dataResponse.free_period);
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('accessToken');
                sessionStorage.removeItem('paymentId');
                sessionStorage.removeItem('mobile');
            })
            .catch(error => {
                setLoading(false);
                try {}
                catch (err) {}
            })
    }

    return (
        <div className={style.container}>
            <Loading loading={loading} />
            <div className={style.row}>
                <h3>What is THYDOR® ?</h3>
                <p>Suffering from Hypothyroidism? Not willing to see a doctor every time to regulate your meds? THYroid DOse Regulator or THYDOR® tells you how much you need to change your Levothyroxine dose based on the most recent TSH values.</p>
            </div>
            <div className={style.row}>
                <h3>How it works ?</h3>
                <div className={style.rowText}><span className={style.point}>1</span><p>Enter your most recent TSH value (mIU/L) (not more than 5 days ago)</p></div>
                <div className={style.rowText}><span className={style.point}>2</span><p>Enter the dose (in mcg) of Levothyroxine you are taking every day.</p></div>
                <div className={style.rowText}><span className={style.point}>3</span><p>Let THYDOR® tell you how much Levothyroxine you need now to keep yourself healthy.</p></div>
            </div>
            <div className={style.disclaimerRow}>
                <div className={style.arrowRow} onClick={()=> setShowDisclaimer(!showDisclaimer)}>
                    <p className={style.header}>Disclaimer</p>
                    {showDisclaimer ? <img src={ArrowDownIcon} alt="img"/> : <img src={ArrowUpIcon} alt="img"/>}
                </div>
                {showDisclaimer ?
                    <ul>
                        <p className={style.text}><li>{`THYDOR® is an approximation and never a substitute for consulting a physician.`}</li></p>
                        <p className={style.text}><li>{`There are many causes of falsely high or falsely low TSH values. Check yourself for these conditions since THYDOR® does not take these into account.`}</li></p>
                        <p className={style.text}><li>{`Consult a physician immediately in case of :`}</li></p>
                        <dl>
                        <p className={style.text}><dd>{`- Palpitations`}</dd></p>
                        <p className={style.text}><dd>{`- Fatigue`}</dd></p>
                        <p className={style.text}><dd>{`- Excessive sweating`}</dd></p>
                        </dl>
                        <p className={style.text}><li>{`The Payment made for this service is not refundable.`}</li></p>
                    </ul> :
                    null}
                {freePeriod ?
                <button className={clsx(style.button, "form-button")} onClick={() => props.history.push('/estimator')}>Next</button>:
                <button className={clsx(style.button, "form-button")} onClick={() => props.history.push('/signIn')}>Proceed to Sign in</button>}
            </div>
        </div>
    )
}

export default Disclaimer;