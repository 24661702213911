export const UPDATE_AUTH = "authState:"

export function updateAuthState(authState){
    return{
        type: UPDATE_AUTH,
        payload: {
            authState: authState
        }
    }
}
