import React from 'react';
import clsx from 'clsx';
import SuccessIcon from 'Assets/success.svg';
import style from './PaymentSuccessful.module.css';

function PaymentSuccessful(props) {

    const urlParams = new URLSearchParams(window.location.search);
    const paymentId = urlParams.get('paymentId');
    const quantity = urlParams.get('quantity');
    const accessToken = urlParams.get('accessToken');

    sessionStorage.setItem('paymentId', paymentId);

    return (
        <div className={"app-form-page"}>
            <div className={style.messageBox}>
                <img className={style.icon} src={SuccessIcon} alt="" />
                <p className={style.successMessage}>{accessToken ? "Booking Successful" : "Payment Successful"}</p>
                {accessToken ?
                    <React.Fragment>
                        <p className="form-text">{`You have booked ${quantity} uses. Your Token ID is:`}</p>
                        <div className={style.accessToken}>{accessToken}</div>
                    </React.Fragment> :
                    <React.Fragment>
                        <p className="form-text">{`You have purchased ${quantity} uses. Your order ID is:`}</p>
                        <div className={style.accessToken}>{paymentId}</div>
                    </React.Fragment>}
                    {/* // <p className="form-text">You can use THYDOR® by entering this token at the <span className={style.link}>login page</span></p>:null} */}
                    <p className="form-text">You can use THYDOR® by entering this token at the login page</p> 
            </div>
            {accessToken ?
                <p className={style.backBtn} onClick={() => props.history.push('/paymentRequest')}>Go Back</p> :
                <button className={clsx(style.button, "form-button")} onClick={() => props.history.push('/estimator')} type="button" >Use THYDOR®</button>}
        </div>
    )
}

export default PaymentSuccessful;