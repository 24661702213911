import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import jwt_decode from 'jwt-decode';
import api from 'Utility/axios.js';
import SendImg from 'Assets/send.svg';
import MobileImg from 'Assets/mobile.svg';
import OtpImg from 'Assets/credit-card.svg';
import CheckImg from 'Assets/check-circle.svg';
import Loading from 'Loading/Loading';
import style from './Authentication.module.css';

function Authentication(props) {

    const [loading, setLoading] = useState(false);
    const [mobileNo, setMobileNo] = useState("");
    const [otp, setOtp] = useState("");
    const [verify, setVerify] = useState(false);
    const [serverError, setServerError] = useState("");

    useEffect(() => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('paymentId');
        sessionStorage.removeItem('mobile');
    }, [])

    function signIn(event){
        event.preventDefault();
        if (verify)
            verifyOtp();
        else
            sendOtp();
    };

    function sendOtp(){
        setLoading(true);
        var data = {"mobile_no": mobileNo}
        api.post(`/mobileOtp`, data)
            .then(response => {
                setLoading(false);
                setVerify(true);
            })
            .catch(error => {
                setLoading(false);
                try {
                    var errorResponse = error.response.data;
                    setServerError(errorResponse.error.message)
                }
                catch (err) {
                    setServerError('Something went wrong please try again later.');
                }
            })
    };

    function verifyOtp(){
        setLoading(true);
        var data = {"mobile_no": mobileNo, "otp": otp}
        api.post(`/validateOtp`, data)
            .then(response => {
                setLoading(false);
                var dataResponse = response.data.data;
                const token = dataResponse.token;
                const decoded_json = jwt_decode(token);
                sessionStorage.setItem('token', token);
                sessionStorage.setItem('mobile', mobileNo);
                if (decoded_json.estimation_left > 0)
                    props.history.push('/estimator');
                else
                    props.history.push('/paymentRequest');
            })
            .catch(error => {
                setLoading(false);
                try {
                    var errorResponse = error.response.data;
                    setServerError(errorResponse.errorMessage)
                }
                catch (err) {
                    setServerError('Something went wrong please try again later.');
                }
            })
    }

    return (
        <div className={style.container}>
            <Loading loading={loading} />
            <p className="form-header">Sign In</p>
            <form className={style.formDetails} onSubmit={(e) => signIn(e)} id="signInForm">
                <p className="form-text">Enter your mobile number to Sign In</p>
                <div className={style.row}>
                    <div className={clsx(style.inputField, "form-inputField")}>
                        <img src={MobileImg} alt='' />
                        <input value={mobileNo} type="number" placeholder="+91 - Enter mobile number" required={true} step="1"
                            onChange={(e) => setMobileNo(e.target.value)}
                            onInvalid={(e) => {
                                if (e.target.value && e.target.value.toString().length !== 10)
                                    e.target.setCustomValidity('Please provide valid Mobile No')
                                else
                                    e.target.setCustomValidity('Please fill in Mobile No')
                            }}
                            onInput={(e) => {
                                if (e.target.value && e.target.value.toString().length !== 10)
                                    e.target.setCustomValidity('Please provide valid Mobile No')
                                else
                                    e.target.setCustomValidity('')
                            }}
                            title={mobileNo ? "Mobile No" : "Please fill in Mobile No"} />
                    </div>
                    <button className={style.verifyBtn}><img alt='' src={SendImg} />Send OTP</button>
                </div>
                {verify ?
                    <React.Fragment>
                        <div className={style.row}>
                            <div className={clsx(style.otpField, "form-inputField")}>
                                <img src={OtpImg} alt='' />
                                <input value={otp} type="number" placeholder="Enter OTP" required={true} step="1"
                                    onChange={(e) => setOtp(e.target.value)}
                                    onInvalid={(e) => {
                                        if (e.target.value && e.target.value.toString().length !== 6)
                                            e.target.setCustomValidity('Please provide valid OTP')
                                        else
                                            e.target.setCustomValidity('Please fill in OTP')
                                    }}
                                    onInput={(e) => {
                                        if (e.target.value && e.target.value.toString().length !== 6)
                                            e.target.setCustomValidity('Please provide valid OTP')
                                        else
                                            e.target.setCustomValidity('')
                                    }}
                                    title={mobileNo ? "OTP" : "Please fill in OTP"} />
                            </div>
                            <p className={style.note}>If you didn’t receive a code? <button type="button" onClick={() =>sendOtp()}>Resend Code</button></p>
                        </div>
                        <button className={style.signInBtn} type="submit" form="signInForm">
                            <img src={CheckImg} alt="img" />Sign In</button>
                    </React.Fragment> : null}
                    {serverError ? <p className={style.error}>{serverError}</p>: null}
                    <p className={style.divider} onClick={() => props.history.push('/tokenPayment')}>Or, Click here <br/> To use THYDOR® via Token / Payment ID</p>
            </form>
        </div>
    );
};

export default Authentication;