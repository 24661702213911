import React, { useState, useEffect } from 'react';
import api from 'Utility/axios.js';
import Loading from 'Loading/Loading';
import useRazorpay from "react-razorpay";
import PinIcon from 'Assets/pin.svg';
import OfferImg from 'Assets/free-trial.png';
import ArrowUpIcon from 'Assets/arrow-up.svg';
import ArrowDownIcon from 'Assets/arrow-down.svg';
import clsx from 'clsx'
import style from './TokenPayment.module.css';

function TokenPayment(props) {
    const Razorpay = useRazorpay();

    const urlParams = new URLSearchParams(window.location.search);
    const [loading, setLoading] = useState(false);
    const [accessToken, setAccessToken] = useState("");
    const [validToken, setValidToken] = useState(false);
    const [remainingEstimations, setRemainingEstimations] = useState(0);
    const [totalEstimations, setTotalEstimations] = useState(0);
    const [trialPeriod, setTrialPeriod] = useState(false);
    const [serviceCost, setServiceCost] = useState(0);
    const [serverError, setServerError] = useState("");
    const [showBreakUp, setShowBreakUp] = useState(false);

    useEffect(() => {
        getServiceCost();
        if (urlParams.get('accessToken'))
            changeAccessToken(urlParams.get('accessToken'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function getServiceCost() {
        setLoading(true);
        api.get('/getServiceCost')
            .then(response => {
                setLoading(false);
                var dataResponse = response.data;
                setServiceCost(parseInt(dataResponse.data.service_cost));
            })
            .catch(error => {
                setLoading(false);
            })
    }

    function onSubmit(event) {
        event.preventDefault();
        var data = { "access_token": accessToken, "api_url": process.env.REACT_APP_SERVER, "origin_url": window.location.origin, "trial_offer": trialPeriod }
        setLoading(true);
        api.post('/tokenPaymentRequest', data)
            .then(response => {
                setLoading(false);
                var dataResponse = response.data.data;
                const paymentResponse = dataResponse.payment_request;
                sessionStorage.setItem('accessToken', accessToken);
                if (paymentResponse.id && paymentResponse.status === "created")
                    razorPay(paymentResponse);
                else
                    setServerError('Something went wrong please try again later.');
            })
            .catch(error => {
                setLoading(false);
                try {
                    var errorResponse = error.response.data;
                    setServerError(errorResponse.error.message);
                }
                catch (err) {
                    setServerError('Something went wrong please try again later.');
                }
            })
    };

    function razorPay(paymentResponse){
        const options = {
            key: process.env.REACT_RAZORPAY_KEY, 
            amount: paymentResponse.amount, 
            currency: paymentResponse.currency,
            name: "FITTO THYDOR",
            order_id: paymentResponse.id, 
            handler: function (response) {
                verifyPayment(response)
            },
        };
        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
            setServerError('Payment Failed');
        });
        rzp1.open();
    };

    function verifyPayment(paymentResponse){
        var data = paymentResponse
        setLoading(true);
        api.post('/verifyTokenPayment', data)
            .then(response => {
                setLoading(false)
                var dataResponse = response.data.data;
                props.history.push(`/paymentSuccessful/?paymentId=${dataResponse.payment_id}&quantity=${1}`);
            })
            .catch(error => {
                setLoading(false);
                try {
                    var errorResponse = error.response.data;
                    setServerError(errorResponse.error.message);
                }
                catch (err) {
                    setServerError('Something went wrong please try again later.');
                }
            })
    };

    function changeAccessToken(value) {
        setAccessToken(value);
        if (value.length === 7) {
            setServerError("");
            validateToken(value);
        }
        else if (value.length === 20 || value.length === 18) {
            setServerError("");
            validatePaymentId(value);
        }
    };

    function validatePaymentId(token) {
        setLoading(true);
        api.get(`/validatePaymentId/?access_token=${token}`)
            .then(response => {
                var dataResponse = response.data;
                setValidToken(true)
                setRemainingEstimations(dataResponse.data.estimations_left);
                setTotalEstimations(dataResponse.data.total_estimations);
                if (dataResponse.data.access_token)
                    sessionStorage.setItem('accessToken', dataResponse.data.access_token);
                else if (dataResponse.data.token)
                    sessionStorage.setItem('token', dataResponse.data.token);
                sessionStorage.setItem('paymentId', token);
                setLoading(false);
                if (dataResponse.data.estimations_left > 0)
                    props.history.push('/estimator');
            })
            .catch(error => {
                setLoading(false);
                try {
                    var errorResponse = error.response.data;
                    setServerError(errorResponse.error.message);
                }
                catch (err) {
                    setServerError('Something went wrong please try again later.');
                }
            })
    };

    function validateToken(token) {
        setLoading(true);
        api.get(`/validateAccessToken/?access_token=${token}`)
            .then(response => {
                var dataResponse = response.data.data;
                setLoading(false);
                setValidToken(true);
                setRemainingEstimations(dataResponse.estimations_left);
                setTotalEstimations(dataResponse.total_estimations);
                if (dataResponse.trial_period === true && dataResponse.remaining_trial_estimations > 0) {
                    setTrialPeriod(true);
                    sessionStorage.setItem('accessToken', token)
                }
            })
            .catch(error => {
                setLoading(false);
                try {
                    var errorResponse = error.response.data;
                    setServerError(errorResponse.error.message);
                }
                catch (err) {
                    setServerError('Something went wrong please try again later.');
                }
            })
    };

    return (
        <div className={style.container}>
            <Loading loading={loading} />
            <p className="form-header">Enter Token / Payment ID</p>
            <form onSubmit={(event) => onSubmit(event)}>
                <div className={clsx(style.accessTokenInput, "form-inputField")}>
                    <div className={style.accessTokenField}>
                        <img src={PinIcon} alt="" />
                        <input value={accessToken} type="text" placeholder='Enter Token/Payment ID' required
                            title={accessToken ? "Token/Payment ID" : "Please enter Token/Payment ID"}
                            onChange={(event) => changeAccessToken(event.target.value)} />
                    </div>
                    {validToken ? <p>{`${remainingEstimations} out of ${totalEstimations} services available`}</p> : null}
                </div>
                {trialPeriod && validToken && remainingEstimations !== 0 ?
                    <React.Fragment>
                        <button className={clsx(style.button, "form-button")}>
                            <img className={style.offerImg} src={OfferImg} alt="img" />
                            <p>{`Use THYDOR® for free `}<span style={{ textDecoration: "line-through" }}>{`(\u20B9 ${serviceCost})`}</span></p>
                        </button>
                        <p className={style.offerNote}>{`Congrats, Use your Free Trial - `}<b>{`\u20B9${serviceCost} off`}</b></p>
                    </React.Fragment> :
                    <React.Fragment>
                        {validToken && !serverError && remainingEstimations !== 0 ? <button className={clsx(style.button, "form-button")}>{serviceCost > 0 ? `Buy Now For Single Use \u20B9${serviceCost}` : 'Book Now'}</button> : null}
                    </React.Fragment>
                }
                {validToken && remainingEstimations !== 0 ?
                    <React.Fragment>
                        {!showBreakUp ?
                            <p className={style.breakdownTrigger} onClick={() => setShowBreakUp(true)}>View Breakup <img src={ArrowDownIcon} alt="img" /></p> :
                            <p className={style.breakdownTrigger} onClick={() => setShowBreakUp(false)}>Hide Breakup <img src={ArrowUpIcon} alt="img" /></p>
                        }
                        {showBreakUp ?
                            <div className={style.breakupTable}>
                                <div className={style.tableRow}>
                                    <p className={style.rowDesc}>Total no of service(s)</p><p className={style.rowValue}>{1}</p>
                                </div>
                                <div className={style.tableRow}>
                                    <p className={style.rowDesc}>Total service charge</p><p className={style.rowValue}>{`\u20B9 ${serviceCost}`}</p>
                                </div>
                                <div className={style.tableRow}>
                                    <p className={style.rowDesc}>Discount</p><p className={style.rowValue}>{`\u20B9 ${serviceCost}`}</p>
                                </div>
                                <div className={style.tableRow}>
                                    <p className={style.rowDesc}>Payment to be made</p><p className={style.rowValue}>{`\u20B9 ${0}`}</p>
                                </div>
                            </div> : null}
                        {!trialPeriod ?
                            <React.Fragment>
                                {/* <p className={style.note}>*convenience fee and GST will be added to the price</p> */}
                                <p className={style.note}>*convenience fee and GST will be added to the price</p>
                            </React.Fragment>
                        :null}
                    </React.Fragment> : null}
                {serverError ? <p className={style.error}>{serverError}</p> : null}
            </form>
        </div>
    )
}

export default TokenPayment;