import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import api from 'Utility/axios.js';
import Loading from 'Loading/Loading';
import DoseImg from 'Assets/pill.svg';
import ThyroidImg from 'Assets/thyroid.svg';
import DangerIcon from 'Assets/danger.svg';
import SuccessIcon from 'Assets/success.svg';
import style from './Estimator.module.css'

function Estimator(props) {

    const [loading, setLoading] = useState(false);
    const [tsh, setTsh] = useState("");
    const [dailyDose, setDailyDose] = useState("");
    const [serverError, setServerError] = useState("");
    const [consultTrigger, setConsultTrigger] = useState(false);
    const [disableEstimator, setDisableEstimator] = useState(false);
    const [disableMessage, setDisableMessage] = useState("");
    const [consultMessage, setConsultMessage] = useState("");
    const [estimation, setEstimation] = useState("");
    const [totalEstimations, setTotalEstimations] = useState(0);
    const [estimationsLeft, setEstimationsLeft] = useState(0);
    const [freePeriod, setFreePeriod] = useState(true);
    const [rechargeMessage, setRechargeMessage] = useState(false);

    useEffect(() => {
        if (sessionStorage.getItem('token') !== null)
            validateUser();
        else if (sessionStorage.getItem('accessToken') !== null)
            validateAccessToken();
        else
            checkFreePeriod();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setServerError("");
    }, [tsh, dailyDose]);

    function validateUser() {
        const token = sessionStorage.getItem('token');
        const header = {
            'Authorization': 'JWT ' + token
        }
        setLoading(true);
        api.get('/validateUser', { headers: header })
            .then(response => {
                var dataResponse = response.data.data;
                setTotalEstimations(dataResponse.total_estimations);
                setEstimationsLeft(dataResponse.estimations_left);
                setLoading(false);
                setFreePeriod(false);
                if (dataResponse.estimations_left === 0)
                    setRechargeMessage(true);
            })
            .catch(error => {
                setLoading(false);
            })
    };

    function checkFreePeriod() {
        setLoading(true);
        api.get(`/checkFreePeriod`)
            .then(response => {
                setLoading(false);
                var dataResponse = response.data.data;
                var freePeriodValue = dataResponse.free_period;
                setFreePeriod(freePeriodValue);
                if (!freePeriodValue) {
                    props.history.push('/');
                }
            })
            .catch(error => {
                setLoading(false);
                try { }
                catch (err) { }
            })
    };

    function validateAccessToken() {
        var token = sessionStorage.getItem('accessToken');
        var paymentId = sessionStorage.getItem('paymentId');
        api.get(`/validateAccessToken/?access_token=${token}&payment_id=${paymentId}`)
            .then(response => {
                var dataResponse = response.data.data;
                setFreePeriod(false);
                setLoading(false);
                setEstimationsLeft(dataResponse.estimations_left);
                setTotalEstimations(dataResponse.total_estimations);
                if (dataResponse.estimations_left === 0)
                    setRechargeMessage(true);
            })
            .catch(error => {
                setLoading(false);
                try {
                    var errorResponse = error.response.data;
                    setServerError(errorResponse.error.message);
                }
                catch (err) {
                    setServerError('Something went wrong please try again later.');
                }
            })
    };

    function reset() {
        setServerError("");
        setConsultTrigger(false);
        setConsultMessage("");
        setEstimation("");
        setDisableEstimator(false);
        setDisableMessage("");
    };

    function resetForm() {
        setTsh("");
        setDailyDose("");
        reset();
    }

    function submitForm(event) {
        event.preventDefault();
        // if (dailyDose % 12.5 !== 0) {
        if (dailyDose < 12.5) {
            setDisableEstimator(true);
            // setDisableMessage("Daily Dose Levothyroxine value must be a multiple of 12.5")
            setDisableMessage("Daily Dose Levothyroxine value must be greater than 12.5")
        }
        else if (tsh <= 0.001) {
            setConsultTrigger(true);
            setConsultMessage('tshCount')
        }
        else {
            setLoading(true);
            var data = { "tsh": parseFloat(tsh), "daily_dose": parseFloat(dailyDose) }
            const token = sessionStorage.getItem('token');
            const accessToken = sessionStorage.getItem('accessToken');
            const payment_id = sessionStorage.getItem('paymentId');
            var header = {}
            var url = "/thydorEstimator";
            if (token)
                header = { 'Authorization': 'JWT ' + token }
            else if (accessToken) {
                data['access_token'] = accessToken;
                url = '/tokenThydorEstimator';
                if (payment_id)
                    data['payment_id'] = payment_id;
            }
            api.post(url, data, { headers: header })
                .then(response => {
                    var dose = response.data.dose;
                    if (dose <= 0) {
                        setConsultTrigger(true);
                        setConsultMessage('0 mcg')
                    }
                    else if (dose > 125) {
                        setConsultTrigger(true);
                        setConsultMessage('125 mcg')
                    }
                    else
                        setEstimation(dose);
                    setLoading(false);
                    setServerError("");
                    window.scrollTo(0, document.body.scrollHeight);
                    if (token)
                        validateUser();
                    else if (accessToken)
                        validateAccessToken();
                })
                .catch(error => {
                    setLoading(false);
                    try {
                        var errorResponse = error.response.data;
                        setServerError(errorResponse.error.message || errorResponse.detail || "Something went wrong please try again later.")
                    }
                    catch (err) {
                        setServerError('Something went wrong please try again later.');
                    }
                })
        }
    };

    return (
        <form className={style.formPage} onSubmit={(e) => submitForm(e)}>
            <Loading loading={loading} />
            {!freePeriod ?
                <div className={style.desc}>
                    <React.Fragment>
                        <p>{`${estimationsLeft} out of ${totalEstimations} services available`}</p>
                        {sessionStorage.getItem('token') ? <span onClick={() => props.history.push('/paymentRequest')}>Repurchase / Book</span> : null}
                    </React.Fragment>
                </div> : null}
            <p className="form-text">Enter your details</p>
            <div style={rechargeMessage ? { opacity: "0.5", pointerEvents: "none", cursor: "not-allowed" } : null}>

            <div className={style.patientDetails}>
                <div className={clsx(style.inputField, "form-inputField")}>
                    <img src={ThyroidImg} alt='' />
                    <input value={tsh} type="number" placeholder="Most recent TSH value (mIU/L) (not more than 5 days ago)" min={0} required={true} step="0.000001" inputMode="decimal" autoFocus
                        onChange={(e) => { setTsh(e.target.value); reset() }}
                        onInvalid={(e) => {
                            if (e.target.value && e.target.value < 1)
                                e.target.setCustomValidity('Please provide proper TSH value')
                            else
                                e.target.setCustomValidity('Please fill in TSH value')
                        }}
                        onInput={(e) => e.target.setCustomValidity('')}
                        title={tsh ? "TSH" : "Please fill in TSH value"} />
                </div>
                <div className={clsx(style.inputField, "form-inputField")} style={disableEstimator ? { border: "3px solid #FE3D3D" } : null}>
                    <img src={DoseImg} alt='' />
                    <input value={dailyDose} type="number" placeholder="Current daily dose (in mcg) of Levothyroxine" min={0.1} required={true} step="0.000001" inputMode="decimal"
                        onChange={(e) => { setDailyDose(e.target.value); reset() }}
                        onInvalid={(e) => {
                            if (e.target.value && e.target.value < 1)
                                e.target.setCustomValidity('Please provide proper Levothyroxine value')
                            else
                                e.target.setCustomValidity('Please fill in daily dose (in mcg) of Levothyroxine')
                        }}
                        onInput={(e) => e.target.setCustomValidity('')}
                        title={dailyDose ? "Levothyroxine" : "Please fill in daily dose (in mcg) of Levothyroxine"} />
                </div>
            </div>
            {disableEstimator ?
                <div className={style.disableMessage}>
                    <img src={DangerIcon} alt='img' />
                    {disableMessage}
                </div> : null}
            <div style={disableEstimator ? { opacity: "0.5", pointerEvents: "none" } : null}>
                <p className={style.reset} onClick={() => resetForm()}>Reset</p>
                <button className={clsx(style.button, "form-button")} >Calculate Levothyroxine Dose</button>
            </div>
            </div>
                {consultTrigger ?
                    <div className={style.disableMessage}>
                        <img src={DangerIcon} alt='img' />
                        {consultMessage === "0 mcg" ?
                            <p><b>Consult a Physician</b> as calculated value is <b>0 mcg.</b></p> :
                            <React.Fragment>
                                {consultMessage === "125 mcg" ?
                                    <p><b>Consult a Physician</b> as calculated value is greater than <b>125 mcg.</b></p> :
                                    <p><b>Consult a Physician</b> TSH value is too low.</p>}
                            </React.Fragment>}
                    </div> : null}
                {estimation ?
                    <React.Fragment>
                        <div className={style.messageBox}>
                            <img className={style.icon} src={SuccessIcon} alt="" />
                            {tsh < 0.5 || tsh > 3 ?
                                <p className={style.successMessage}>You will need to change your dose. From now you should take</p> :
                                <p className={style.successMessage}>No need to change dose, keep taking</p>}
                            <div className={style.estimation}>{`${estimation} mcg`}</div>
                            <p className={style.successMessage}>of Levothyroxine.</p>
                        </div>
                        <p className={style.note}>Recheck Blood for TSH values after 3 weeks from starting this new dose.</p>
                    </React.Fragment> : null}
                {rechargeMessage || serverError === "No uses left, pls buy more." ?
                    <div className={style.disableMessage}>
                        <div className={style.rechargeMessage}>
                            <img src={DangerIcon} alt='img' />
                            <p>All services used</p>
                        </div>
                        <span onClick={() => {
                            if (sessionStorage.getItem('token'))
                                props.history.push('/paymentRequest')
                            else
                                props.history.push(`/tokenPayment/?accessToken=${sessionStorage.getItem('accessToken')}`)
                        }}>{"Repurchase / Book"}</span>
                    </div> : null}
                {serverError ? <p className={style.error}>{serverError}</p> : null}
        </form>
    )
};

export default Estimator;