import React from 'react';
import { Backdrop } from '@material-ui/core';
import ReactLoading from 'react-loading';
import { useStyles } from './style';

function Loading(props) {
    
    const classes = useStyles();

    return (
        <Backdrop open={props.loading} className={classes.backdrop}>
            <ReactLoading
                type={"spin"}
                color={"#1b66a3"}
                height={70}
                width={70}
                className={classes.reactLoader}
            />
        </Backdrop>
    );
};

export default Loading;