import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: 99999,
      color: "#fff",
      overflow: 'hidden'
    },
    reactLoader: {
        margin: "auto"
    }
}));

export {useStyles};