import {UPDATE_AUTH} from "../Actions/authAction";
import {REMOVE_AUTH} from "../Actions/removeAuth";

const initialState = 
    { 
        isLoggedIn: false, email_id: '', user_id: ''
    }

export default function authStateReducer(state=initialState, {type, payload}){
    switch(type){
        case UPDATE_AUTH:
            return payload.authState;
        case REMOVE_AUTH:
            return state;
        default:
            return state;
    }
}