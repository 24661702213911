import React, { useState, useEffect } from 'react';
import clsx from "clsx";
import api from 'Utility/axios.js';
import useRazorpay from "react-razorpay";
import {
    RadioGroup, FormControlLabel, Radio, Checkbox
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Loading from 'Loading/Loading';
import ArrowUpIcon from 'Assets/arrow-up.svg';
import ArrowDownIcon from 'Assets/arrow-down.svg';
import OfferImg from 'Assets/free-trial.png'
import style from './PaymentRequest.module.css'

const CustomCheckbox = withStyles({
    root: {
        color: "#2163C9",
        '&$checked': {
            color: "#2163C9",
        },
    },
    checked: {},
})((props) => <Checkbox required color="default" {...props} />);

function PaymentRequest(props) {
    const Razorpay = useRazorpay();

    const [loading, setLoading] = useState(false);
    const [paymentType, setPaymentType] = useState("purchase");
    const [useCount, setUseCount] = useState(1);
    const [serviceCost, setServiceCost] = useState(0);
    const [serverError, setServerError] = useState("");
    const [checkBox, setCheckBox] = useState(false);
    const [trialOffer, setTrialOffer] = useState(false);
    const [offerLeft, setOfferLeft] = useState(0);
    const [showOffer, setShowOffer] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [discountedPrice, setDiscountedPrice] = useState(0);
    const [showBreakUp, setShowBreakUp] = useState(false);
    const [bulkDiscount, setBulkDiscount] = useState(false);
    const [discountPercent, setDiscountPercent] = useState(0);

    useEffect(() => {
        getServiceCost();
        getTrialOffer();
    }, [])

    useEffect(() => {
        calculateTrialOffer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useCount, serviceCost, offerLeft])

    function getServiceCost() {
        setLoading(true);
        api.get('/getServiceCost')
            .then(response => {
                setLoading(false);
                var dataResponse = response.data;
                setServiceCost(parseInt(dataResponse.data.service_cost));
            })
            .catch(error => {
                setLoading(false);
            })
    };

    function getTrialOffer() {
        setLoading(true);
        const token = sessionStorage.getItem('token');
        const header = {
            'Authorization': 'JWT ' + token
        }
        api.get('/getOffer', { headers: header })
            .then(response => {
                setLoading(false);
                var dataResponse = response.data.data;
                setBulkDiscount(dataResponse.bulk_discount);
                if (dataResponse.trial_offer === true) {
                    setShowOffer(true);
                    setTrialOffer(dataResponse.trial_offer);
                    setOfferLeft(dataResponse.offers_left);
                }
            })
            .catch(error => {
                setLoading(false);
            })
    }

    function onSubmit(event) {
        event.preventDefault();
        if (trialOffer && !showOffer)
            calculateTrialOffer();
        else if (paymentType === "purchase")
            makePaymentRequest();
        else
            generateAccessToken();
    };

    function calculateTrialOffer() {
        var totalValue = serviceCost * useCount;
        var discountValue = 0;
        if (useCount < offerLeft)
            discountValue = 0
        else
            discountValue = Math.max(0, (serviceCost * useCount) - (serviceCost * offerLeft));
        setDiscountPercent(0);
        if (bulkDiscount && useCount - offerLeft > 5) {
            var discount = 0;
            if (useCount - offerLeft > 100) {
                // discount = Math.round((15 / 100) * discountValue);
                discount = parseInt((15 / 100) * discountValue);
                setDiscountPercent(15);
            }
            else {
                // discount = Math.round((10 / 100) * discountValue);
                discount = parseInt((10 / 100) * discountValue);
                setDiscountPercent(10);
            }
            discountValue = discountValue - discount;
        }
        setTotalPrice(totalValue);
        setDiscountedPrice(discountValue);
    };

    function generateAccessToken() {
        setLoading(true);
        var data = { "no_of_services": parseInt(useCount) }
        api.post('/generateAccessToken', data)
            .then(response => {
                var dataResponse = response.data;
                const accessToken = dataResponse.data.access_token;
                props.history.push(`/paymentSuccessful/?accessToken=${accessToken}&quantity=${useCount}`)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false);
                try {
                    var errorResponse = error.response.data;
                    setServerError(errorResponse.error.message);
                }
                catch (err) {
                    setServerError('Something went wrong please try again later.');
                }
            })
    };

    function makePaymentRequest() {
        const token = sessionStorage.getItem('token');
        const header = {
            'Authorization': 'JWT ' + token
        }
        var data = {
            "no_of_services": parseInt(useCount),
            "api_url": process.env.REACT_APP_SERVER,
            "origin_url": window.location.origin,
            "trial_offer": trialOffer
        }
        setLoading(true);
        api.post('/paymentRequest', data, { headers: header })
            .then(response => {
                setLoading(false)
                var dataResponse = response.data.data;
                const paymentResponse = dataResponse.payment_request;
                if (paymentResponse.id && paymentResponse.status === "created")
                    razorPay(paymentResponse);
                else
                    setServerError('Something went wrong please try again later.');
            })
            .catch(error => {
                setLoading(false);
                if (error.response.status === 401)
                    props.history.push('/signIn');
                else {
                    try {
                        var errorResponse = error.response.data;
                        setServerError(errorResponse.error.message);
                    }
                    catch (err) {
                        setServerError('Something went wrong please try again later.');
                    }
                }
            })
    };

    function razorPay(paymentResponse){
        const options = {
            key: process.env.REACT_RAZORPAY_KEY, 
            amount: paymentResponse.amount, 
            currency: paymentResponse.currency,
            name: "FITTO THYDOR",
            order_id: paymentResponse.id, 
            prefill: {
                contact: sessionStorage.getItem('mobile'),
              },
            handler: function (response) {
                verifyPayment(response)
            },
        };
        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
            setServerError('Payment Failed');
        });
        rzp1.open();
    };

    function verifyPayment(paymentResponse){
        const token = sessionStorage.getItem('token');
        const header = {
            'Authorization': 'JWT ' + token
        }
        var data = paymentResponse
        setLoading(true);
        api.post('/verifyPayment', data, { headers: header })
            .then(response => {
                setLoading(false)
                var dataResponse = response.data.data;
                props.history.push(`/paymentSuccessful/?paymentId=${dataResponse.payment_id}&quantity=${useCount}`);
            })
            .catch(error => {
                setLoading(false);
                try {
                    var errorResponse = error.response.data;
                    setServerError(errorResponse.error.message);
                }
                catch (err) {
                    setServerError('Something went wrong please try again later.');
                }
            })
    };

    return (
        <div className={style.container}>
            <Loading loading={loading} />
            <p className="form-header">Purchase/ Book</p>
            <form onSubmit={(event) => onSubmit(event)}>
                <p className="form-text">Please choose the no. of times you want to use THYDOR®</p>
                <RadioGroup row value={paymentType} onChange={(event) => setPaymentType(event.target.value)}>
                    <FormControlLabel
                        value="purchase" control={<Radio color="primary" />} label="Purchase" className={style.radio}
                    />
                    <FormControlLabel
                        value="book" control={<Radio color="primary" />} label="Book" className={style.radio}
                    />
                </RadioGroup>
                <div className={style.inputBlock}>
                    <button type="button" className={style.actionButton} onClick={() => setUseCount(useCount - 1)} disabled={useCount <= 1 ? true : false}>-</button>
                    <div className={clsx(style.inputField, "form-inputField")}>
                        <input value={useCount} type="number" required={true} min={1}
                            onChange={(e) => setUseCount(parseInt(e.target.value || 0))} placeholder="Put No. Of services"
                            onInvalid={(e) => e.target.setCustomValidity('Please enter No. Of services')}
                            onInput={(e) => e.target.setCustomValidity('')}
                            title={"No. Of services"} />
                    </div>
                    <button type="button" className={style.actionButton} onClick={() => setUseCount(useCount + 1)}>+</button>
                </div>
                {paymentType === "book" ?
                    <FormControlLabel
                        className={style.label}
                        control={<CustomCheckbox
                            checked={checkBox}
                            onChange={() => setCheckBox(!checkBox)} />}
                        label="By booking you agree to to pay later and use the service"
                    />
                    : null}
                {serverError ? <p className={style.error}>{serverError}</p> : null}
                {paymentType === "book" ?
                    <button className={clsx(style.button, "form-button")}>Book Now</button> :
                    <React.Fragment>
                        {showOffer ?
                            <React.Fragment>
                                <button className={clsx(style.button, "form-button")}>
                                    {showOffer ? <img className={style.offerImg} src={OfferImg} alt="img" /> : null}
                                    {discountedPrice > 0 ? 
                                        <p>{`Pay Now \u20B9 ${discountedPrice}`} 
                                            <span style={{ textDecoration: "line-through" }}>{`(\u20B9 ${totalPrice})`}</span></p> :
                                        <p>{`Use THYDOR® for free `}
                                            <span style={{ textDecoration: "line-through" }}>{`(\u20B9 ${totalPrice})`}</span></p>}
                                </button>
                                <p className={style.offerNote}>{`Congrats, Use your Free Trial - `}<b>{`\u20B9${totalPrice - discountedPrice} off`}</b></p>
                            </React.Fragment> :
                            <button className={clsx(style.button, "form-button")}>
                                {serviceCost > 0 ? 
                                    <p>{`Pay Now \u20B9 ${discountedPrice} `}{totalPrice > discountedPrice ? <span style={{ textDecoration: "line-through" }}>{`(\u20B9 ${totalPrice})`}</span> : null}</p> : `Pay Now`}
                            </button>}
                    </React.Fragment>}
                                
                {paymentType === "purchase" ?
                    <React.Fragment>
                        {discountPercent > 0 && !showOffer ? <p className={style.offerNote}>{`Congrats, Use your discount - `}<b>{`\u20B9${totalPrice - discountedPrice} off`}</b></p>: null}
                        {!showBreakUp ?
                            <p className={style.breakdownTrigger} onClick={() => setShowBreakUp(true)}>View Breakup <img src={ArrowDownIcon} alt="img" /></p> :
                            <p className={style.breakdownTrigger} onClick={() => setShowBreakUp(false)}>Hide Breakup <img src={ArrowUpIcon} alt="img" /></p>
                        }
                        {showBreakUp ?
                            <div className={style.breakupTable}>
                                <div className={style.tableRow}>
                                    <p className={style.rowDesc}>Total no of service(s)</p><p className={style.rowValue}>{useCount}</p>
                                </div>
                                <div className={style.tableRow}>
                                    <p className={style.rowDesc}>Total service charge</p><p className={style.rowValue}>{`\u20B9 ${totalPrice}`}</p>
                                </div>
                                <div className={style.tableRow}>
                                    {/* <p className={style.rowDesc}>Discount {discountPercent > 0 ? `${discountPercent}%` : null}</p><p className={style.rowValue}>{`\u20B9 ${totalPrice - discountedPrice}`}</p> */}
                                    <p className={style.rowDesc}>Discount</p><p className={style.rowValue}>{`\u20B9 ${totalPrice - discountedPrice}`}</p>
                                </div>
                                <div className={style.tableRow}>
                                    <p className={style.rowDesc}>Payment to be made</p><p className={style.rowValue}>{`\u20B9 ${discountedPrice}`}</p>
                                </div>
                            </div> : null}
                    </React.Fragment> : null}
            </form>
            <p className={style.note}>*convenience fee and GST will be added to the price</p>
        </div>
    );
};

export default PaymentRequest;